@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap');
@import url('https://use.typekit.net/hns0kay.css');

* * {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.warning {
	top: 0;
	background-color: lightcoral;
	padding: 12px 0;
	z-index: 999;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: sans-serif;
	margin-bottom: 20px;
	color: aliceblue;

	a {
		color: aliceblue;
		transition: color ease-in-out 150ms;
		&:hover {
			color: DarkRed;
		}
	}
}

body {
	background: #d0ebd1;
	font-family: 'Annie Use Your Telescope', cursive;
	text-align: center;
}

h1 {
	font-family: 'Marydale', sans-serif;
	letter-spacing: 4px;
	color: #000000;
	text-transform: uppercase;
}

canvas {
	display: block;
	margin: 0 auto;
	border: 1px solid rgba(108, 117, 107, 0.4);
	position: absolute;
	top: 20%;
	left: 0;
	right: 0;
	bottom: 0;
}

::selection {
	background: transparent;
}

.flex {
	max-width: 280px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}

.stats > * {
	margin-bottom: 5px;
}

.life:before {
	content: url('../img/dino-head.png');
}
